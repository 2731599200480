export default grapesjs.plugins.add('Two-TeaserTile', (editor, options) => {
	editor.BlockManager.add('Two-TeaserTile', {
		category: 'Commerce',
		label: 'Two-TeaserTile',
		attributes: { class: 'fa fa-gift' },
		content: `<div class="Two-TeaserTile TeaserTile container-fluid two-tile-content-container" data-gjs-type="Two-TeaserTile" data-initial-state="true">configure your content</div>`
	});

	// Get DomComponents module
	var comps = editor.DomComponents;
	
	// Get the model and the view from the default Component type
	var defaultType = comps.getType('default');
	var defaultModel = defaultType.model;
	var defaultView = defaultType.view;

	// start configuration Teaser-Tile-Big
	comps.addType('Two-TeaserTile', {
		// Define the Model
		model: defaultModel.extend({
			// Extend default properties
			defaults: Object.assign(
				{},
				defaultModel.prototype.defaults, {
					// Can't drop other elements inside it
					droppable: false,
					// Traits (Settings)
					traits: [
						{
							type: 'text', // Type of the trait
							label: 'header Title', // The label you will see in Settings
							name: 'headerTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1.Tile Element-Title', // The label you will see in Settings
							name: 'elTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1.Tile Link-URL', // The label you will see in Settings
							name: 'linkURL', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1.Tile Link-Title', // The label you will see in Settings
							name: 'linkTitle', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '1.Tile Image URL', // The label you will see in Settings
							name: 'imageURL', // The name of the attribute/property to use on component
						}, {
							type: 'text', // Type of the trait
							label: '2.Tile Element-Title', // The label you will see in Settings
							name: 'elTitle1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2.Tile Link-URL', // The label you will see in Settings
							name: 'linkURL1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2.Tile Link-Title', // The label you will see in Settings
							name: 'linkTitle1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: '2.Tile Image URL', // The label you will see in Settings
							name: 'imageURL1', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible From', // The label you will see in Settings
							name: 'cccStartDate', // The name of the attribute/property to use on component
						},
						{
							type: 'text', // Type of the trait
							label: 'Is Visible To', // The label you will see in Settings
							name: 'cccEndDate', // The name of the attribute/property to use on component
						}
					],
					attributes: {
						headerTitle: '',
						elTitle: '',
						linkURL: '',
						linkTitle: '',
						imageURL: '',
						elTitle1: '',
						linkURL1: '',
						linkTitle1: '',
						imageURL1: '',
						cccStartDate: '',
						cccEndDate: ''
					}
				}
			),
			init() {
				this.on('change:attributes:headerTitle', this.handleTraitChange);
				this.on('change:attributes:elTitle', this.handleTraitChange);
				this.on('change:attributes:linkTitle', this.handleTraitChange);
				this.on('change:attributes:imageURL', this.handleTraitChange);
				this.on('change:attributes:linkURL', this.handleTraitChange);
				this.on('change:attributes:elTitle1', this.handleTraitChange);
				this.on('change:attributes:linkTitle1', this.handleTraitChange);
				this.on('change:attributes:imageURL1', this.handleTraitChange);
				this.on('change:attributes:linkURL1', this.handleTraitChange);
				this.on('change:attributes:cccStartDate', this.handleTraitChange);
				this.on('change:attributes:cccEndDate', this.handleTraitChange);
			},
			handleTraitChange() {
				CCRZ.testaus = this;
				this.attributes.content = this.generateHTML(false);
				this.view.el.innerHTML = this.generateHTML(false);
				$(this.view.el).removeAttr('data-initial-state');
				delete this.view.attr['data-initial-state'];
				delete this.attributes.attributes['data-initial-state'];
			},
			toHTML: function () {
				return this.generateHTML(true);
			},
			generateHTML(includeParentDOMElement) {
				if (typeof includeParentDOMElement == 'undefined') {
					includeParentDOMElement = false;
				}

				let elementString = '';
				let timedAttributes = '';

				if (includeParentDOMElement) {
					if (this.getAttributes().cccStartDate && this.getAttributes().cccEndDate) {
						timedAttributes = ' cccStartDate="' + this.getAttributes().cccStartDate + '" cccEndDate="' +  this.getAttributes().cccEndDate + '"';
					}
					elementString = elementString + '<div class="Two-TeaserTile container-fluid two-tile-content-container" data-gjs-type="Two-TeaserTile"' + timedAttributes + '>';
				}

				elementString += '<div class="two-teaser-tile-container " >';
				if (this.getAttributes().headerTitle) { elementString += '<div class="two-teaser-tile-header"><h2>' + (this.getAttributes().headerTitle).replace('\n', '') + '</h2></div>'; }
				//  ---start 1. Tile ---
				elementString += '<div class="row"><div class="col-xl-6 col-xs-12 col-md-6 mb-4 two-teaser-tile-box" ><div class="card two-teaser-tile">';
				if (this.getAttributes().imageURL1) { elementString += ' <div class="card-body two-teaser-tile-background-image" style="background-image:url(' + this.getAttributes().imageURL + ')" >'; }
				elementString += '<div class="row" ><div class="col fixed-bottom position-absolute py-3 two-teaser-tile-content" >';
				if (this.getAttributes().elTitle1) { elementString += '<div  ><h4 class="two-teaser-tile-subtitle py-3">' + this.getAttributes().elTitle + '</h4></div>'; }
				if (this.getAttributes().linkURL1) {
					elementString += '<div class="d-flex"><i class="icon-forward two-teaser-tile-link-icon mr-2" aria-hidden="true"></i><a href="' + this.getAttributes().linkURL + '" class="two-teaser-tile-link">' + this.getAttributes().linkTitle + '</a></div>';
				}
				elementString += '</div></div></div></div></div>';
				// --end 1. Tile--

				//--start 2. Tile--
				elementString += '<div class="col-xl-6 col-xs-12 col-md-6 mb-4 two-teaser-tile-box" ><div class="card two-teaser-tile">';
				if (this.getAttributes().imageURL1) { elementString += ' <div class="card-body two-teaser-tile-background-image" style="background-image:url(' + (this.getAttributes().imageURL1).replace('\n', '') + ')" >'; }
				elementString += '<div class="row" ><div class="col fixed-bottom position-absolute py-3 two-teaser-tile-content" >';
				if (this.getAttributes().elTitle1) { elementString += '<div  ><h4 class="two-teaser-tile-subtitle py-3">' + (this.getAttributes().elTitle1).replace('\n', '') + '</h4></div>'; }
				if (this.getAttributes().linkURL1) {
					elementString += '<div class="d-flex"><i class="icon-forward two-teaser-tile-link-icon mr-2" aria-hidden="true"></i><a href="' + (this.getAttributes().linkURL1).replace('\n', '') + '" class="two-teaser-tile-link">' + this.getAttributes().linkTitle1 + '</a></div>';
				}
				elementString += '</div></div></div></div></div></div></div>';
				//--end 2. Tile--

				elementString += '</div></div>';
				if (includeParentDOMElement) {
					elementString += '</div>';
				}
				return elementString;
			}
		},
			// The second argument of .extend are static methods and we'll put inside our
			// isComponent() method. As you're putting a new Component type on top of the stack,
			// not declaring isComponent() might probably break stuff, especially if you extend
			// the default one.
			{
				isComponent(el) {
					if (el.getAttribute && el.getAttribute('data-gjs-type') == 'Two-TeaserTile') {
						return {
							type: 'Two-TeaserTile'
						};
					}
				}
			}
		)
	});
});